import React from "react"
import Seo from "../../components/seo"
import Layout from "../../Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import { Link } from "gatsby"

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <Breadcrumbs title={"Privacy Policy"} />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-10 col-lg-8">
            <h2 className="h4">What is this Privacy Policy for?</h2>
            <p>
              We recognize that your privacy is very important to you and that
              it is your right to control your personal information. We know
              that providing personal information is an act of trust and we take
              that trust seriously.
            </p>

            <h2 className="h4">Collection and usage of Information</h2>
            <p>
              You will be asked to provide basic personal information when you
              are purchasing items via our website. These information we collect
              are used to fulfil the intended request made by you. With given
              permission by you, we'll use the details provided by you in
              another manner, such as featured emails, to which you will have
              the option to opt out.
            </p>

            <h2 className="h4">Commitment to Data Security</h2>
            <p>
              The information is used solely to ensure smooth delivery of
              products and for notifying you of changes or updates to the
              Website/Services, if necessary.
            </p>
            <p>
              Owita Organics will not store your credit card or debit card
              details. All the payments are done through PayHere, which is a
              well-known certified organization for handling credit card
              transactions. Further Owita Organics will not sell, rent or
              otherwise disclose any of your personal information, including
              your email address and phone number, to any third party without
              your consent unless either:
            </p>
            <ol>
              <li>We are required to by law</li>
              <li>If it is necessary to provide the Services</li>
            </ol>
            <hr className="uk-margin-top" />
            <p>
              If you have any further queries to be clarified, please do not
              hesitate to contact us via our FB messenger in the website or
              sending us en <Link to="/contact-us">email</Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage
